<template>
  <div class="common email-info-page">
    <!-- 未绑定邮箱 -->
    <template v-if="!isBindEmail">
      <h2 class="page-title">绑定邮箱</h2>
      <div class="page-content">
        <div class="form form-input">
          <div class="input-div">
            <label for="name" class="form-label">邮箱</label>
            <div class="input">
              <div class="input-content">
                <input
                  type="text"
                  placeholder="请输入邮箱"
                  v-model="email"
                  form="email"
                  v-checkParam="rules.email"
                />
                <a class="btn-send" @click="handleSendCode">{{ codeText }}</a>
              </div>
            </div>
          </div>
          <button
            class="button is-show-small"
            @click="handleSendCode"
            :disabled="codeText.indexOf('秒') != -1"
          >
            {{ codeText }}
          </button>
          <div class="input-div code-div-large">
            <label for="name" class="form-label">验证码</label>
            <div class="code-input-div">
              <input
                type="text"
                class="code-input pc"
                @keyup="handleInput(0, 'pc')"
                @focus="handleInputFocus(0, 'pc')"
              />
              <input
                type="text"
                class="code-input pc"
                @keyup="handleInput(1, 'pc')"
                @focus="handleInputFocus(1, 'pc')"
              />
              <input
                type="text"
                class="code-input pc"
                @keyup="handleInput(2, 'pc')"
                @focus="handleInputFocus(2, 'pc')"
              />
              <input
                type="text"
                class="code-input pc"
                @keyup="handleInput(3, 'pc')"
                @focus="handleInputFocus(3, 'pc')"
              />
            </div>
          </div>
          <div class="send-code">
            <p class="code-title">请输入您的验证码</p>
            <div class="code-input-div">
              <input
                type="text"
                class="code-input wap"
                @keyup="handleInput(0, 'wap')"
                @focus="handleInputFocus(0, 'wap')"
              />
              <input
                type="text"
                class="code-input wap"
                @keyup="handleInput(1, 'wap')"
                @focus="handleInputFocus(1, 'wap')"
              />
              <input
                type="text"
                class="code-input wap"
                @keyup="handleInput(2, 'wap')"
                @focus="handleInputFocus(2, 'wap')"
              />
              <input
                type="text"
                class="code-input wap"
                @keyup="handleInput(3, 'wap')"
                @focus="handleInputFocus(3, 'wap')"
              />
            </div>
          </div>
        </div>
        <button
          class="button base-btn"
          v-checkSubmit="'email'"
          :submit="handleAddEmail"
          :disabled="disabled"
        >
          提交
        </button>
      </div>
    </template>

    <!-- 已绑定邮箱 -->
    <div class="page-content" v-if="isBindEmail">
      <div class="email-info">
        <img src="@/assets/images/dialog/email.png" alt="" />
        <p class="email-title">已认证邮箱</p>
        <p class="email-title">{{ email }}</p>
      </div>
    </div>
    <Dialog
      :dialogObj="dialogObj"
      @confirm="handleDialogConfirm"
      @cancel="handleDialogCancel"
    />
  </div>
</template>

<script>
import account from "@/apis/account.js";
import Dialog from "@/components/dialog.vue";
export default {
  name: "Email",
  components: {
    Dialog,
  },
  data() {
    return {
      isBindEmail: false,
      email: "",
      code: "",
      coderArr: [],
      timer: "",
      codeText: "获取验证码",
      rules: {
        email: [
          { rule: "required", msg: "请输入邮箱" },
          {
            rule: /^[A-Za-z0-9\u4e00-\u9fa5\w|\-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            msg: "请输入正确的邮箱",
          },
        ],
      },
      disabled: false,
      dialogObj: {},
    };
  },
  methods: {
    //发送验证码sendEmailCode
    handleSendCode() {
      if (!this.email || !this.rules.email[1].rule.test(this.email)) {
        this.$toast("请输入正确的邮箱");
        return;
      }
      if (this.codeText.indexOf("秒") > -1) {
        return;
      }
      account.sendEmailCode({ email: this.email }).then((res) => {
        this.$toast(res.Data);
        if (res.IsSuccess) {
          this.countNum();
        }
      });
    },
    //发送验证码倒计时
    countNum() {
      clearInterval(this.timer);
      let count = 60;
      let text = "";
      this.timer = setInterval(() => {
        if (count <= 0) {
          count = 0;
          text = "重新获取验证码";
          clearInterval(this.timer);
        } else {
          count -= 1;
          text = count + "秒后重新获取";
        }
        this.codeText = text;
      }, 1000);
    },
    //点击提交按钮
    handleAddEmail() {
      if (this.disabled) {
        return;
      }
      this.$toast.loading({
        duration: 0,
        mask: true,
        message: "提交中...",
      });
      if (this.coderArr.length < 4) {
        this.$toast.clear();
        this.$toast("请输入正确的验证码");
        return;
      }
      this.disabled = true;
      let params = {
        email: this.email,
        code: this.coderArr.join(""),
      };
      account.bindEmail(params).then((res) => {
        this.$toast.clear();
        if (res.IsSuccess) {
          this.handleDialog();
        } else {
          this.disabled = false;
          this.$toast(res.Data);
        }
      });
    },
    handleDialog() {
      this.dialogObj = {
        isShow: true,
        title: "",
        content: "邮箱绑定成功",
        showEmailImg: true,
        confirmText: "返回首页",
        cancelText: "进入个人资料",
      };
    },
    handleDialogConfirm() {
      this.$router.push({
        name: "index",
      });
    },
    handleDialogCancel() {
      this.isBindEmail = true;
    },
    handleInput(val, name) {
      this.coderArr[val] = document.getElementsByClassName(
        `code-input ${name}`
      )[val].value;
      if (val < 3) {
        document.getElementsByClassName(`code-input ${name}`)[val + 1].focus();
      }
      if (val == 3) {
        document.getElementsByClassName("base-btn")[0].focus();
      }
    },
    handleInputFocus(val, name) {
      this.coderArr[val] = "";
      document.getElementsByClassName(`code-input ${name}`)[val].value = "";
    },
  },
  created() {
    //是否绑定邮箱
    this.email = this.$store.state.login.userInfo.email;
    if (this.email) {
      this.isBindEmail = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/account/email.scss";
</style>
